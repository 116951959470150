import React from 'react'
import { Button, Hidden } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

interface AddButtonProps {
  onClick: () => void
}

export function ShowPasswordButton({ onClick }: AddButtonProps) {
  return (
    <Button
      size="small"
      type="button"
      color="primary"
      onClick={onClick}
      style={{ backgroundColor: 'white', padding: 0, position: 'absolute', right: 0, background: 'transparent' }}
    >
      {/* <Hidden smDown>{text}</Hidden> */}
      <Hidden>
        <RemoveRedEyeIcon style={{ fill: '#1870c7' }} />
      </Hidden>
    </Button>
  )
}
