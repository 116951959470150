import React, { useContext, useEffect, useState } from 'react'
import { MenuItem, Select, Grid, Box, IconButton, Divider } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { Chart } from 'src/components/Charts/Chart'
import { labels } from 'src/labels/main_labels'
import SelectDialog from 'src/components/Form/SelectDialog'
import { AuthContext } from 'src/context/auth/authContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { InfoContext } from 'src/context/info/infoContext'
import { getAllCompanyConfig } from 'src/services/companies.services'
import { getAllBookingsByCompany, getAllBookingsByCompanyAndStore, getAllIncidentsByCompany } from 'src/services/bookings.services'
import moment from 'moment'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { PieChartCategory } from 'src/components/Charts/PieChart'

export default function Statitics() {
  const { showHeader } = useContext(HeaderContext)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState('Anual')
  const { machines, processes, sectors, pathologies, allCompanies, allStores, fetchPathologies } = useContext(InfoContext)
  const { userData } = useContext(AuthContext)
  const { showSnackbar } = useContext(AlertContext)

  const [company, setCompany] = useState<any | never>(null)
  const [allCompanyStores, setAllCompanyStores] = useState<any[]>([])
  const [allCompanyStoresPie, setAllCompanyStoresPie] = useState<any[]>([])

  const [store, setStore] = useState<any | never>({ id: null, name: 'Todas' })
  const [affectedZone, setAffectedZone] = useState<any | never>({ id: null, name: 'Todas' })
  const [asistence, setAsistence] = useState<any | never>({ id: null, name: 'Todas' })
  const [bookingType, setBookingType] = useState<any | never>({ id: null, name: 'Todas' })
  const [process, setProcess] = useState<any | never>({ id: null, name: 'Todas' })
  const [machine, setMachine] = useState<any | never>({ id: null, name: 'Todas' })
  const [age, setAge] = useState<any | never>({ id: null, name: 'Todas' })
  const [gender, setGender] = useState<any | never>({ id: null, name: 'Todas' })
  const [sector, setSector] = useState<any | never>({ id: null, name: 'Todas' })
  const [jobPosition, setJobPosition] = useState<any | never>({ id: null, name: 'Todas' })
  const [timeWindow, setTimeWindow] = useState<any | never>({ id: null, name: 'Todas' })
  const [daysOff, setDaysOff] = useState<any | never>({ id: null, name: 'Todas' })
  const [timeCompany, setTimeCompany] = useState<any | never>({ id: null, name: 'Todas' })
  const [bookings, setBookings] = useState<any[]>([])
  const [bookingsPie, setBookingsPie] = useState<any[]>([])
  const [companyIncidents, setCompanyIncidents] = useState<any[]>([])
  const [configCompanyAdmin, setConfigCompanyAdmin] = useState([])
  const [chartData, setChartData] = useState<any | never>({ id: null, name: 'Todas' })
  const [pieDataChart, setPieDataChart] = useState<any | never>(null)
  const [incidentStatus, setIncidentStatus] = useState<any | never>({ id: null, name: 'Todas' })
  const [companyPie, setCompanyPie] = useState<any | never>({ id: null, name: 'Todas' })
  const [storePie, setStorePie] = useState<any | never>({ id: null, name: 'Todas' })
  const [categoryPie, setCategoryPie] = useState<any | never>({ id: null, name: 'Todas' })

  const handleChange = (event: any) => {
    event.target.name === 'year' && setYear(event.target.value)
    event.target.name === 'month' && setMonth(event.target.value)
  }

  const resetComponent = () => {
    setYear(new Date().getFullYear())
    setMonth('Anual')
    setCompany(null)
    setAllCompanyStores([])
    setCompanyIncidents([])
    setStore(null)
    setAffectedZone(null)
    setBookingType(null)
    setProcess(null)
    setMachine(null)
    setSector(null)
    setGender(null)
    setAge(null)
    setJobPosition(null)
    setTimeCompany(null)
    setTimeWindow(null)
    setDaysOff(null)
    setAsistence(null)
    setConfigCompanyAdmin([])
    setIncidentStatus(null)
    setCompanyPie(null)
    setStorePie(null)
    setCategoryPie(null)
    setBookingsPie([])
  }

  const Header = () => {
    return <div></div>
  }

  useEffect(() => {
    showHeader(Header)

    return () => resetComponent()
  }, [])

  const loadCompanyStores = async () => {
    if (userData.role.id === 'role3') {
      const companyStores = allStores.filter((elm: any) => elm.companyId === userData.companyId)

      if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
    } else if (userData.role.id === 'role1') {
      const companyStores = allStores.filter((elm: any) => elm.companyId === company.id)
      if (companyStores && companyStores.length) setAllCompanyStores(companyStores)
    }
  }

  const loadCompanyStoresPie = async () => {
    if (userData.role.id === 'role3') {
      const companyStores = allStores.filter((elm: any) => elm.companyId === userData.companyId)

      if (companyStores && companyStores.length) setAllCompanyStoresPie(companyStores)
    } else if (userData.role.id === 'role1') {
      const companyStores = allStores.filter((elm: any) => elm.companyId === companyPie.id)
      if (companyStores && companyStores.length) setAllCompanyStoresPie(companyStores)
    }
  }

  const loadCompanyConfig = async () => {
    const companyConfig = await getAllCompanyConfig(company.id || userData.companyId)

    if (companyConfig && companyConfig.length) setConfigCompanyAdmin(companyConfig)
  }

  const loadIncidents = async () => {
    if (!companyPie && userData.role.id === 'role1') return
    if (userData && userData.role.id === 'role1') {
      const incidents = await getAllIncidentsByCompany(companyPie.id)
      if (incidents && incidents.length) {
        setCompanyIncidents(incidents)
      }
    } else if (userData && userData.role.id === 'role3') {
      const incidents = await getAllIncidentsByCompany(userData.companyId)
      if (incidents && incidents.length) {
        setCompanyIncidents(incidents)
      }
    }
  }

  const fetchBookingsList = async () => {
    try {
      if (userData.companyId && userData.storeId) {
        const resp = await getAllBookingsByCompanyAndStore(userData.companyId, userData.storeId)
        const data = resp.filter((row: any) => row.deleted !== true)

        setBookings(data)
      } else if (userData.companyId || company.id) {
        const resp = await getAllBookingsByCompany(userData.companyId || company.id)
        const data = resp.filter((row: any) => row.deleted !== true)

        setBookings(data)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  const fetchBookingsListPie = async () => {
    try {
      if (userData.companyId && userData.storeId) {
        const resp = await getAllBookingsByCompanyAndStore(userData.companyId, userData.storeId)
        const data = resp.filter((row: any) => row.deleted !== true)

        setBookingsPie(data)
      } else if (userData.companyId || companyPie.id) {
        const resp = await getAllBookingsByCompany(userData.companyId || companyPie.id)
        const data = resp.filter((row: any) => row.deleted !== true)
        setBookingsPie(data)
      }
    } catch (error) {
      if (error instanceof Error) showSnackbar('error', error.message)
    }
  }

  useEffect(() => {
    if (company || (userData.companyId && allStores && userData)) loadCompanyStores()
    if (company || userData.companyId) fetchPathologies(company?.id || userData.companyId)
    if (company || userData.companyId) loadCompanyConfig()
    if (company || userData.companyId) fetchBookingsList()
  }, [company, userData])

  useEffect(() => {
    if (companyPie === 'Todas') setAllCompanyStoresPie([])
    if (companyPie || (userData.companyId && allStores && userData)) loadCompanyStoresPie()
    if (companyPie || userData.companyId) fetchBookingsListPie()
    if (companyPie || userData.companyId) loadIncidents()
  }, [companyPie, userData])

  const cleanFilters = () => {
    setCompany(null)
    setStore(null)
    setAffectedZone(null)
    setBookingType(null)
    setProcess(null)
    setMachine(null)
    setSector(null)
    setGender(null)
    setAge(null)
    setJobPosition(null)
    setTimeCompany(null)
    setTimeWindow(null)
    setDaysOff(null)
    setAsistence(null)
    setYear(new Date().getFullYear())
    setMonth('Anual')
    setChartData([])
    setIncidentStatus(null)
  }

  const cleanFiltersPie = () => {
    setCompanyPie({ id: null, name: 'Todas' })
    setStorePie({ id: null, name: 'Todas' })
    setCategoryPie({ id: null, name: 'Todas' })
    setPieDataChart(null)
  }

  // CALC CHART

  const getDaysBetween = (date1: string) => {
    const start = moment(date1, 'YYYY-MM-DD')
    const current = moment().startOf('day')

    // Difference in number of days

    return moment.duration(start.diff(current)).asDays()
  }
  useEffect(() => {
    if (bookings) {
      if (month !== 'Anual') {
        const selectMonth = parseInt(month + 1).toString()

        const count: any = new Array(new Date(year, +selectMonth, 0).getDate())
          .fill(new Date(year, +selectMonth, 0).getDate())
          .map((elm, idx) => {
            return { month: elm - idx, Tiempo: 0, Sesiones: 0 }
          })
          .reverse()

        bookings
          .filter((e: any) => new Date(e.date).getMonth() === +selectMonth - 1 && new Date(e.date).getFullYear() === year)
          .filter((booking: any) => {
            return (
              (!userData.companyId || booking.companyId === userData.companyId) &&
              (company === null || booking?.companyId === company?.id) &&
              (store === null || store.id === null || booking?.storeId === store.id) &&
              (affectedZone === null || affectedZone.id === null || booking.affectedZone.id === affectedZone.id) &&
              (bookingType === null || bookingType.id === null || booking[bookingType.id]) &&
              (asistence === null || asistence.id === null || booking.completed === asistence) &&
              (process === null ||
                process.id === null ||
                booking?.campaign?.all.process.id === process?.id ||
                booking.incident?.all.process.id === process?.id) &&
              (machine === null ||
                machine.id === null ||
                booking?.campaign?.all.machine.id === machine?.id ||
                booking.incident?.all.machine.id === machine?.id) &&
              (sector === null ||
                sector.id === null ||
                booking?.campaign?.all.sector.id === sector?.id ||
                booking.incident?.all.sector.id === sector?.id) &&
              (gender === null || gender.id === null || booking?.patient?.all.gender.id === gender.id) &&
              (timeWindow === null || timeWindow.id === null || booking.incident?.all.timeWindow.id === timeWindow.id) &&
              (incidentStatus === null || incidentStatus.id === null || booking.incident?.closed.closeReason.id === incidentStatus.id) &&
              (daysOff === null || daysOff.id === null || +booking.incident?.all.daysSinceDayOff >= +daysOff.id) &&
              (timeCompany === null ||
                timeCompany.id === null ||
                getDaysBetween(booking?.patient?.all.workingSince) * -1 > +timeCompany.id) &&
              (jobPosition === null || jobPosition.id === null || booking?.patient?.all.role.id === jobPosition.id) &&
              (age === null ||
                age.id === null ||
                (moment().diff(moment(booking?.patient?.all.birthday, 'YYYYMMDD'), 'years') > age.id.substring(0, 2) &&
                  moment().diff(moment(booking?.patient?.all.birthday, 'YYYYMMDD'), 'years') < age.id.substring(2, 4)))
            )
          })
          .forEach((elm: any) => {
            const thisDay = new Date(elm.date).getDate() - 1

            count[thisDay] ? (count[thisDay].Tiempo += 30) : (count[thisDay] = { month: thisDay, Tiempo: 30, Sesiones: 1 })
            count[thisDay] ? (count[thisDay].Sesiones += 1) : (count[thisDay] = { month: thisDay, Tiempo: 30, Sesiones: 1 })
          })

        setChartData(count)
      } else {
        const count: any = []
        for (let i = 0; i < 12; i++) {
          count.push({ month: i + 1, Tiempo: 0, Sesiones: 0 })
        }

        bookings
          .filter((e: any) => new Date(e.date).getFullYear() === year)
          .filter(
            (booking: any) =>
              (company === null || booking?.companyId === company?.id) &&
              (store === null || store.id === null || booking?.storeId === store.id) &&
              (affectedZone === null || affectedZone.id === null || booking.affectedZone.id === affectedZone.id) &&
              (bookingType === null || bookingType.id === null || booking[bookingType.id]) &&
              (asistence === null || asistence.id === null || booking.completed === asistence) &&
              (process === null ||
                process.id === null ||
                booking?.campaign?.all.process.id === process?.id ||
                booking.incident?.all.process.id === process?.id) &&
              (machine === null ||
                machine.id === null ||
                booking?.campaign?.all.machine.id === machine?.id ||
                booking.incident?.all.machine.id === machine?.id) &&
              (sector === null ||
                sector.id === null ||
                booking?.campaign?.all.sector.id === sector?.id ||
                booking.incident?.all.sector.id === sector?.id) &&
              (gender === null || gender.id === null || booking?.patient?.all.gender.id === gender.id) &&
              (timeWindow === null || timeWindow.id === null || booking.incident?.all.timeWindow.id === timeWindow.id) &&
              (daysOff === null || daysOff.id === null || +booking.incident?.all.daysSinceDayOff >= +daysOff.id) &&
              (timeCompany === null ||
                timeCompany.id === null ||
                getDaysBetween(booking?.patient?.all.workingSince) * -1 > +timeCompany.id) &&
              (jobPosition === null || jobPosition.id === null || booking?.patient?.all.role.id === jobPosition.id) &&
              (age === null ||
                age.id === null ||
                (moment().diff(moment(booking?.patient?.all.birthday, 'YYYYMMDD'), 'years') > age.id.substring(0, 2) &&
                  moment().diff(moment(booking?.patient?.all.birthday, 'YYYYMMDD'), 'years') < age.id.substring(2, 4)))
          )
          .forEach((elm: any) => {
            const thisMonth = new Date(elm.date).getMonth()

            count[thisMonth] && (count[thisMonth].Tiempo += 30)
            count[thisMonth] && (count[thisMonth].Sesiones += 1)
          })

        setChartData(count)
      }
    }
  }, [
    bookings,
    month,
    year,
    company,
    store,
    affectedZone,
    pathologies,
    bookingType,
    process,
    machine,
    sector,
    timeCompany,
    daysOff,
    gender,
    timeWindow,
    daysOff,
    age,
    jobPosition,
    incidentStatus
  ])

  useEffect(() => {
    if ((companyPie?.name !== 'Todas' || userData?.companyId) && storePie?.name === 'Todas' && categoryPie?.name !== 'Todas') {
      if (categoryPie?.id === 'affectedZone') {
        const pieDataInfo: any[] = []
        let totalOrders = 0
        bookingsPie.forEach(
          (book) => !book.deleted && pieDataInfo.push({ name: book.affectedZone.name, value: 0, id: book.affectedZone.id })
        )
        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.affectedZone.id === pieCat.id) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'position') {
        const pieDataInfo: any[] = []
        let totalOrders = 0
        bookingsPie.forEach(
          (book) => !book.deleted && pieDataInfo.push({ name: book.patient?.all?.role?.name, value: 0, id: book.patient?.all?.role?.id })
        )
        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.patient?.all?.role?.id === pieCat.id) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'incidentvscampaign') {
        const pieDataInfo: any[] = [
          { name: 'Incidentes', id: 'incident', value: 0 },
          { name: 'Campañas', id: 'campaign', value: 0 },
          { name: 'Patología Común', id: 'Patología Común', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach((booking) =>
          pieDataInfo.forEach((pieCat) => {
            if (booking.bookingReason?.name === 'Patología Común') {
              pieCat.value++
              totalOrders++
            } else if (booking[pieCat.id]) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'completed') {
        const pieDataInfo: any[] = [
          { name: 'Asiste', id: 'assist', value: 0 },
          { name: 'No asiste', id: 'noassist', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach(
          (booking) => {
            if (booking.signImg) {
              pieDataInfo[0].value++
              totalOrders++
            } else {
              pieDataInfo[1].value++
              totalOrders++
            }
          }
          // pieDataInfo.forEach((pieCat) => {
          //   console.log(pieCat)
          //   if (booking[pieCat.id]) {
          //     pieCat.value++
          //     totalOrders++
          //   }
          // })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'workWindow') {
        const pieDataInfo: any[] = [
          { name: 'Mañana', id: 'morning', value: 0 },
          { name: 'Tarde', id: 'afternoon', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach(
          (booking) => {
            if (booking?.incident?.all?.timeWindow?.id === 'morning') {
              pieDataInfo[0].value++
              totalOrders++
            } else if (booking?.incident?.all?.timeWindow?.id === 'afternoon') {
              pieDataInfo[1].value++
              totalOrders++
            }
          }
          // pieDataInfo.forEach((pieCat) => {
          //   console.log(pieCat)
          //   if (booking[pieCat.id]) {
          //     pieCat.value++
          //     totalOrders++
          //   }
          // })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'sex') {
        const pieDataInfo: any[] = [
          { name: 'Hombre', id: 'Hombre', value: 0 },
          { name: 'Mujer', id: 'Mujer', value: 0 },
          { name: '-', id: '-', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach(
          (booking) => {
            if (booking?.patient?.all?.gender.name === 'Hombre') {
              pieDataInfo[0].value++
              totalOrders++
            } else if (booking?.patient?.all?.gender.name === 'Mujer') {
              pieDataInfo[1].value++
              totalOrders++
            } else if (booking?.patient?.all?.gender.name === '-') {
              pieDataInfo[2].value++
              totalOrders++
            }
          }
          // pieDataInfo.forEach((pieCat) => {
          //   console.log(pieCat)
          //   if (booking[pieCat.id]) {
          //     pieCat.value++
          //     totalOrders++
          //   }
          // })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'closeReason') {
        // LOGICA POR INCIDENTES

        // const pieDataInfo: any[] = [
        //   { name: 'Alta', id: 'alta', value: 0 },
        //   { name: 'Sin Resolución', id: 'sinresolución', value: 0 },
        //   { name: 'Max Sesiones', id: 'full', value: 0 },
        //   { name: 'Abierta', id: 'open', value: 0 }
        // ]

        // let totalOrders = 0

        // companyIncidents.forEach((incident) => {
        //   if (incident?.closed?.closeReason?.id === 'alta') pieDataInfo[0].value++
        //   else if (incident?.closed?.closeReason?.id === 'sinresolución') pieDataInfo[1].value++
        //   else if (incident?.closed?.closeReason?.id === 'full') pieDataInfo[2].value++
        //   else pieDataInfo[3].value++
        //   totalOrders++
        // })

        // LOGICA POR CITAS

        const pieDataInfo: any[] = [
          { name: 'Alta', id: 'alta', value: 0 },
          { name: 'Sin Resolución', id: 'sinresolución', value: 0 },
          { name: 'Max Sesiones', id: 'full', value: 0 },
          { name: 'Abierta', id: 'open', value: 0 }
        ]

        let totalOrders = 0

        bookingsPie.forEach((booking) => {
          if (booking?.incident?.all?.id) {
            const inc = companyIncidents.find((inc) => inc.id === booking?.incident?.id)

            if (inc?.closed?.closeReason?.id === 'alta') pieDataInfo[0].value++
            else if (inc?.closed?.closeReason?.id === 'sinresolución') pieDataInfo[1].value++
            else if (inc?.closed?.closeReason?.id === 'full') pieDataInfo[2].value++
            else pieDataInfo[3].value++
            totalOrders++
          }
        })

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'age') {
        const pieDataInfo: any[] = [
          { name: '18-25', id: '1825', value: 0 },
          { name: '25-30', id: '2530', value: 0 },
          { name: '30-45', id: '3035', value: 0 },
          { name: '35-40', id: '3540', value: 0 },
          { name: '45-50', id: '4550', value: 0 },
          { name: '50-55', id: '5055', value: 0 },
          { name: '55-60', id: '5560', value: 0 },
          { name: '60-65', id: '6065', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach((booking) => {
          const currentAge = moment().diff(moment(booking?.patient?.all.birthday, 'YYYYMMDD'), 'years')

          currentAge >= 18 && currentAge <= 25 && pieDataInfo[0].value++
          currentAge >= 25 && currentAge <= 30 && pieDataInfo[1].value++
          currentAge >= 30 && currentAge <= 35 && pieDataInfo[2].value++
          currentAge >= 35 && currentAge <= 45 && pieDataInfo[3].value++
          currentAge >= 45 && currentAge <= 50 && pieDataInfo[4].value++
          currentAge >= 50 && currentAge <= 55 && pieDataInfo[5].value++
          currentAge >= 55 && currentAge <= 60 && pieDataInfo[6].value++
          currentAge >= 60 && currentAge <= 65 && pieDataInfo[7].value++

          totalOrders++
        })

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'workingSince') {
        const pieDataInfo: any[] = [
          { name: '3 meses', id: '90', value: 0 },
          { name: '6 meses', id: '180', value: 0 },
          { name: '9 meses', id: '270', value: 0 },
          { name: '1 año', id: '365', value: 0 },
          { name: '2 años', id: '730', value: 0 },
          { name: '3 años', id: '1095', value: 0 },
          { name: '5 años', id: '1825', value: 0 },
          { name: '8 años', id: '2920', value: 0 },
          { name: '10 años', id: '3650', value: 0 },
          { name: '10 o + años', id: '3651', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach((booking) => {
          const currentWorkingSince = getDaysBetween(booking?.patient?.all.workingSince) * -1

          currentWorkingSince >= 90 && currentWorkingSince < 180 && pieDataInfo[0].value++
          currentWorkingSince >= 180 && currentWorkingSince < 270 && pieDataInfo[1].value++
          currentWorkingSince >= 270 && currentWorkingSince < 365 && pieDataInfo[2].value++
          currentWorkingSince >= 365 && currentWorkingSince < 730 && pieDataInfo[3].value++
          currentWorkingSince >= 730 && currentWorkingSince < 1095 && pieDataInfo[4].value++
          currentWorkingSince >= 1095 && currentWorkingSince < 1825 && pieDataInfo[5].value++
          currentWorkingSince >= 1825 && currentWorkingSince < 2920 && pieDataInfo[6].value++
          currentWorkingSince >= 2920 && currentWorkingSince < 3650 && pieDataInfo[7].value++
          currentWorkingSince >= 3650 && pieDataInfo[7].value++
          totalOrders++
        })
        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'process') {
        const pieDataInfo: any[] = []
        let totalOrders = 0

        bookingsPie.forEach((book) => {
          if (!book.deleted) {
            if (book.campaign) {
              pieDataInfo.push({ name: book.campaign.all.process.name, value: 0, id: book.campaign.all.process.id })
            } else if (book.incident) {
              pieDataInfo.push({ name: book.incident.all.process.name, value: 0, id: book.incident.all.process.id })
            }
          }
        })

        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.campaign?.all?.process?.id === pieCat.id || booking.incident?.all?.process?.id === pieCat.id) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'sector') {
        const pieDataInfo: any[] = []
        let totalOrders = 0

        bookingsPie.forEach((book) => {
          if (!book.deleted) {
            if (book.campaign) {
              pieDataInfo.push({ name: book.campaign.all.sector.name, value: 0, id: book.campaign.all.sector.id })
            } else if (book.incident) {
              pieDataInfo.push({ name: book.incident.all.sector.name, value: 0, id: book.incident.all.sector.id })
            }
          }
        })

        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.campaign?.all?.sector?.id === pieCat.id || booking.incident?.all?.sector?.id === pieCat.id) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'machine') {
        const pieDataInfo: any[] = []
        let totalOrders = 0

        bookingsPie.forEach((book) => {
          if (!book.deleted) {
            if (book.campaign) {
              pieDataInfo.push({ name: book.campaign.all.machine.name, value: 0, id: book.campaign.all.machine.id })
            } else if (book.incident) {
              pieDataInfo.push({ name: book.incident.all.machine.name, value: 0, id: book.incident.all.machine.id })
            }
          }
        })

        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.campaign?.all?.machine?.id === pieCat.id || booking.incident?.all?.machine?.id === pieCat.id) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      }
    } else if (
      (companyPie?.name !== 'Todas' || userData?.companyId) &&
      (storePie?.name !== 'Todas' || userData?.companyId) &&
      categoryPie?.name !== 'Todas'
    ) {
      if (categoryPie?.id === 'affectedZone') {
        const pieDataInfo: any[] = []
        let totalOrders = 0
        bookingsPie.forEach(
          (book) =>
            !book.deleted &&
            [userData.storeId, storePie.id].includes(book.storeId) &&
            pieDataInfo.push({ name: book.affectedZone.name, value: 0, id: book.affectedZone.id })
        )
        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.affectedZone.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId)) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'position') {
        const pieDataInfo: any[] = []
        let totalOrders = 0
        bookingsPie.forEach(
          (book) =>
            !book.deleted &&
            [userData.storeId, storePie.id].includes(book.storeId) &&
            pieDataInfo.push({ name: book.patient?.all?.role?.name, value: 0, id: book.patient?.all?.role?.id })
        )
        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (booking.patient?.all?.role?.id === pieCat.id && [(userData.storeId, storePie.id)].includes(booking.storeId)) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'incidentvscampaign') {
        const pieDataInfo: any[] = [
          { name: 'Incidentes', id: 'incident', value: 0 },
          { name: 'Campañas', id: 'campaign', value: 0 },
          { name: 'Patología Común', id: 'Patología Común', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach((booking) =>
          pieDataInfo.forEach((pieCat) => {
            if (booking.bookingReason?.name === 'Patología Común' && [(userData.storeId, storePie.id)].includes(booking.storeId)) {
              pieCat.value++
              totalOrders++
            } else if (booking[pieCat.id] && [(userData.storeId, storePie.id)].includes(booking.storeId)) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'completed') {
        const pieDataInfo: any[] = [
          { name: 'Asiste', id: 'assist', value: 0 },
          { name: 'No asiste', id: 'noassist', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach(
          (booking) => {
            if (booking.signImg && [(userData.storeId, storePie.id)].includes(booking.storeId)) {
              pieDataInfo[0].value++
              totalOrders++
            } else if ([userData.storeId, storePie.id].includes(booking.storeId)) {
              pieDataInfo[1].value++
              totalOrders++
            }
          }
          // pieDataInfo.forEach((pieCat) => {
          //   console.log(pieCat)
          //   if (booking[pieCat.id]) {
          //     pieCat.value++
          //     totalOrders++
          //   }
          // })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'workWindow') {
        const pieDataInfo: any[] = [
          { name: 'Mañana', id: 'morning', value: 0 },
          { name: 'Tarde', id: 'afternoon', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach(
          (booking) => {
            if (booking?.incident?.all?.timeWindow?.id === 'morning' && [userData.storeId, storePie.id].includes(booking.storeId)) {
              pieDataInfo[0].value++
              totalOrders++
            } else if (
              booking?.incident?.all?.timeWindow?.id === 'afternoon' &&
              [userData.storeId, storePie.id].includes(booking.storeId)
            ) {
              pieDataInfo[1].value++
              totalOrders++
            }
          }
          // pieDataInfo.forEach((pieCat) => {
          //   console.log(pieCat)
          //   if (booking[pieCat.id]) {
          //     pieCat.value++
          //     totalOrders++
          //   }
          // })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'sex') {
        const pieDataInfo: any[] = [
          { name: 'Hombre', id: 'Hombre', value: 0 },
          { name: 'Mujer', id: 'Mujer', value: 0 },
          { name: '-', id: '-' }
        ]
        let totalOrders = 0

        bookingsPie.forEach(
          (booking) => {
            if (booking?.patient?.all?.gender.name === 'Hombre' && [userData.storeId, storePie.id].includes(booking.storeId)) {
              pieDataInfo[0].value++
              totalOrders++
            } else if (booking?.patient?.all?.gender.name === 'Mujer' && [userData.storeId, storePie.id].includes(booking.storeId)) {
              pieDataInfo[1].value++
              totalOrders++
            } else if (booking?.patient?.all?.gender.name === '-' && [userData.storeId, storePie.id].includes(booking.storeId)) {
              pieDataInfo[2].value++
              totalOrders++
            }
          }
          // pieDataInfo.forEach((pieCat) => {
          //   console.log(pieCat)
          //   if (booking[pieCat.id]) {
          //     pieCat.value++
          //     totalOrders++
          //   }
          // })
        )

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'closeReason') {
        // LOGICA POR INCIDENTES

        // const pieDataInfo: any[] = [
        //   { name: 'Alta', id: 'alta', value: 0 },
        //   { name: 'Sin Resolución', id: 'sinresolución', value: 0 },
        //   { name: 'Max Sesiones', id: 'full', value: 0 },
        //   { name: 'Abierta', id: 'open', value: 0 }
        // ]

        // let totalOrders = 0

        // companyIncidents.forEach((incident) => {
        //   if (incident?.closed?.closeReason?.id === 'alta') pieDataInfo[0].value++
        //   else if (incident?.closed?.closeReason?.id === 'sinresolución') pieDataInfo[1].value++
        //   else if (incident?.closed?.closeReason?.id === 'full') pieDataInfo[2].value++
        //   else pieDataInfo[3].value++
        //   totalOrders++
        // })

        // LOGICA POR CITAS

        const pieDataInfo: any[] = [
          { name: 'Alta', id: 'alta', value: 0 },
          { name: 'Sin Resolución', id: 'sinresolución', value: 0 },
          { name: 'Max Sesiones', id: 'full', value: 0 },
          { name: 'Abierta', id: 'open', value: 0 }
        ]

        let totalOrders = 0

        bookingsPie.forEach((booking) => {
          if (booking?.incident?.all?.id) {
            const inc = companyIncidents.find((inc) => inc.id === booking?.incident?.id)

            if (inc?.closed?.closeReason?.id === 'alta' && [userData.storeId, storePie.id].includes(booking.storeId)) pieDataInfo[0].value++
            else if (inc?.closed?.closeReason?.id === 'sinresolución' && [userData.storeId, storePie.id].includes(booking.storeId))
              pieDataInfo[1].value++
            else if (inc?.closed?.closeReason?.id === 'full' && [userData.storeId, storePie.id].includes(booking.storeId))
              pieDataInfo[2].value++
            else if ([userData.storeId, storePie.id].includes(booking.storeId)) pieDataInfo[3].value++
            totalOrders++
          }
        })

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'age') {
        const pieDataInfo: any[] = [
          { name: '18-25', id: '1825', value: 0 },
          { name: '25-30', id: '2530', value: 0 },
          { name: '30-45', id: '3035', value: 0 },
          { name: '35-40', id: '3540', value: 0 },
          { name: '45-50', id: '4550', value: 0 },
          { name: '50-55', id: '5055', value: 0 },
          { name: '55-60', id: '5560', value: 0 },
          { name: '60-65', id: '6065', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach((booking) => {
          if ([userData.storeId, storePie.id].includes(booking.storeId)) {
            const currentAge = moment().diff(moment(booking?.patient?.all.birthday, 'YYYYMMDD'), 'years')

            currentAge >= 18 && currentAge <= 25 && pieDataInfo[0].value++
            currentAge >= 25 && currentAge <= 30 && pieDataInfo[1].value++
            currentAge >= 30 && currentAge <= 35 && pieDataInfo[2].value++
            currentAge >= 35 && currentAge <= 45 && pieDataInfo[3].value++
            currentAge >= 45 && currentAge <= 50 && pieDataInfo[4].value++
            currentAge >= 50 && currentAge <= 55 && pieDataInfo[5].value++
            currentAge >= 55 && currentAge <= 60 && pieDataInfo[6].value++
            currentAge >= 60 && currentAge <= 65 && pieDataInfo[7].value++

            totalOrders++
          }
        })

        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'workingSince') {
        const pieDataInfo: any[] = [
          { name: '3 meses', id: '90', value: 0 },
          { name: '6 meses', id: '180', value: 0 },
          { name: '9 meses', id: '270', value: 0 },
          { name: '1 año', id: '365', value: 0 },
          { name: '2 años', id: '730', value: 0 },
          { name: '3 años', id: '1095', value: 0 },
          { name: '5 años', id: '1825', value: 0 },
          { name: '8 años', id: '2920', value: 0 },
          { name: '10 años', id: '3650', value: 0 },
          { name: '10 o + años', id: '3651', value: 0 }
        ]
        let totalOrders = 0

        bookingsPie.forEach((booking) => {
          if ([userData.storeId, storePie.id].includes(booking.storeId)) {
            const currentWorkingSince = getDaysBetween(booking?.patient?.all.workingSince) * -1

            currentWorkingSince >= 90 && currentWorkingSince < 180 && pieDataInfo[0].value++
            currentWorkingSince >= 180 && currentWorkingSince < 270 && pieDataInfo[1].value++
            currentWorkingSince >= 270 && currentWorkingSince < 365 && pieDataInfo[2].value++
            currentWorkingSince >= 365 && currentWorkingSince < 730 && pieDataInfo[3].value++
            currentWorkingSince >= 730 && currentWorkingSince < 1095 && pieDataInfo[4].value++
            currentWorkingSince >= 1095 && currentWorkingSince < 1825 && pieDataInfo[5].value++
            currentWorkingSince >= 1825 && currentWorkingSince < 2920 && pieDataInfo[6].value++
            currentWorkingSince >= 2920 && currentWorkingSince < 3650 && pieDataInfo[7].value++
            currentWorkingSince >= 3650 && pieDataInfo[7].value++
            totalOrders++
          }
        })
        pieDataInfo.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfo)
      } else if (categoryPie?.id === 'process') {
        const pieDataInfo: any[] = []
        let totalOrders = 0

        bookingsPie.forEach((book) => {
          if (!book.deleted && [userData.storeId, storePie.id].includes(book.storeId)) {
            if (book.campaign) {
              pieDataInfo.push({ name: book.campaign.all.process.name, value: 0, id: book.campaign.all.process.id })
            } else if (book.incident) {
              pieDataInfo.push({ name: book.incident.all.process.name, value: 0, id: book.incident.all.process.id })
            }
          }
        })

        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (
              (booking.campaign?.all?.process?.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId)) ||
              (booking.incident?.all?.process?.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId))
            ) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'sector') {
        const pieDataInfo: any[] = []
        let totalOrders = 0

        bookingsPie.forEach((book) => {
          if (!book.deleted && [userData.storeId, storePie.id].includes(book.storeId)) {
            if (book.campaign) {
              pieDataInfo.push({ name: book.campaign.all.sector.name, value: 0, id: book.campaign.all.sector.id })
            } else if (book.incident) {
              pieDataInfo.push({ name: book.incident.all.sector.name, value: 0, id: book.incident.all.sector.id })
            }
          }
        })

        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (
              (booking.campaign?.all?.sector?.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId)) ||
              (booking.incident?.all?.sector?.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId))
            ) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      } else if (categoryPie?.id === 'machine') {
        const pieDataInfo: any[] = []
        let totalOrders = 0

        bookingsPie.forEach((book) => {
          if (!book.deleted && [userData.storeId, storePie.id].includes(book.storeId)) {
            if (book.campaign) {
              pieDataInfo.push({ name: book.campaign.all.machine.name, value: 0, id: book.campaign.all.machine.id })
            } else if (book.incident) {
              pieDataInfo.push({ name: book.incident.all.machine.name, value: 0, id: book.incident.all.machine.id })
            }
          }
        })

        const pieDataInfoFiltered = pieDataInfo.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))

        bookingsPie.forEach((booking) =>
          pieDataInfoFiltered.forEach((pieCat) => {
            if (
              (booking.campaign?.all?.machine?.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId)) ||
              (booking.incident?.all?.machine?.id === pieCat.id && [userData.storeId, storePie.id].includes(booking.storeId))
            ) {
              pieCat.value++
              totalOrders++
            }
          })
        )

        pieDataInfoFiltered.forEach((pieCategory) => {
          pieCategory.percentage = +((+pieCategory.value * 100) / +totalOrders).toFixed(2)
          pieCategory.value = +pieCategory.value
        })

        setPieDataChart(pieDataInfoFiltered)
      }
    }
  }, [categoryPie, companyPie, storePie])

  return (
    <div style={{ padding: '2rem' }}>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={1}>
          {['role1'].includes(userData.role.id) && (
            <Grid item xs={6} md={4} xl={3}>
              <SelectDialog
                text={labels.es.company}
                setData={setCompanyPie}
                data={companyPie}
                options={[{ id: null, name: 'Todas' }, ...allCompanies]
                  .filter((elm: any) => !elm.deleted)
                  // .sort((a, b) => a.name.localeCompare(b.name))
                  .map((elm: any) => {
                    return { name: elm.name, id: elm.id }
                  })}
                simple
              />
            </Grid>
          )}
          {allCompanyStoresPie.length > 0 && ['role1', 'role3'].includes(userData.role.id) && (
            <Grid item xs={6} md={4} xl={3}>
              <SelectDialog
                text={labels.es.store}
                setData={setStorePie}
                data={storePie}
                options={[
                  { id: null, name: 'Todas' },
                  ...allCompanyStoresPie
                    .filter((elm: any) => !elm.deleted)
                    // .sort((a, b) => a.storeName.localeCompare(b.storeName))
                    .map((elm: any) => {
                      return { name: elm.storeName, id: elm.id }
                    })
                ]}
                simple
              />
            </Grid>
          )}

          <Grid item xs={6} md={4} xl={3}>
            <SelectDialog
              required
              text={labels.es.category}
              setData={setCategoryPie}
              data={categoryPie}
              options={[
                { id: null, name: 'Todos' },
                { name: 'Zona Afectada', id: 'affectedZone' },
                { name: 'Asistencia', id: 'completed' },
                { name: 'Incidente/Campaña/PC', id: 'incidentvscampaign' },
                { name: 'Procesos', id: 'process' },
                { name: 'Sectores', id: 'sector' },
                { name: 'Máquinas', id: 'machine' },
                { name: 'Sexo', id: 'sex' },
                { name: 'Edad', id: 'age' },
                { name: 'Antigüedad', id: 'workingSince' },
                { name: 'Motivo Cierre(Solo incidentes)', id: 'closeReason' },
                { name: 'Puesto', id: 'position' },
                { name: 'Área(Solo incidentes)', id: 'area' }
              ]}
              simple
            />
          </Grid>
          <IconButton sx={{ mx: 2 }} style={{ marginTop: 'auto' }} color="primary" onClick={cleanFiltersPie}>
            <FilterAltOffIcon />
          </IconButton>
        </Grid>
      </Box>
      <PieChartCategory title={'Estadísticas por categoria'} dataChart={pieDataChart && pieDataChart} />
      <Divider color={'#1870c7'} sx={{ my: 1 }} />

      <Box sx={{ mb: 3 }}>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={6}>
            <label style={{ fontSize: 11 }}>Año</label>
            {/* <InputLabel style={{ left: -13, top: -8 }}>Año</InputLabel> */}
            <Select value={year} name={'year'} onChange={handleChange} displayEmpty size="small" fullWidth>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <label style={{ fontSize: 11 }}>Mes</label>
            {/* <InputLabel style={{ left: -13, top: -8 }}>Mes</InputLabel> */}
            <Select name={'month'} value={month} onChange={handleChange} size="small" fullWidth>
              <MenuItem value={'Anual'}>Anual</MenuItem>
              <MenuItem value={0}>Enero</MenuItem>
              <MenuItem value={1}>Febrero</MenuItem>
              <MenuItem value={2}>Marzo</MenuItem>
              <MenuItem value={3}>Abril</MenuItem>
              <MenuItem value={4}>Mayo</MenuItem>
              <MenuItem value={5}>Junio</MenuItem>
              <MenuItem value={6}>Julio</MenuItem>
              <MenuItem value={7}>Agosto</MenuItem>
              <MenuItem value={8}>Septiembre</MenuItem>
              <MenuItem value={9}>Octubre</MenuItem>
              <MenuItem value={10}>Noviembre</MenuItem>
              <MenuItem value={11}>Diciembre</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {userData.role.id === 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                text={labels.es.company}
                setData={setCompany}
                data={company}
                options={[{ id: null, name: 'Todas' }, ...allCompanies]
                  .filter((elm: any) => !elm.deleted)
                  // .sort((a, b) => a.name.localeCompare(b.name))
                  .map((elm: any) => {
                    return { name: elm.name, id: elm.id }
                  })}
                simple
              />
            </Grid>
          )}
          {allCompanyStores.length > 0 && ['role1', 'role3'].includes(userData.role.id) && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                text={labels.es.store}
                setData={setStore}
                data={store}
                options={[
                  { id: null, name: 'Todas' },
                  ...allCompanyStores
                    // .filter((elm: any) => !elm.deleted)
                    // .sort((a, b) => a.storeName.localeCompare(b.storeName))
                    .map((elm: any) => {
                      return { name: elm?.storeName, id: elm?.id }
                    })
                ]}
                simple
              />
            </Grid>
          )}
          {pathologies.length > 0 && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                text={labels.es.pathologies}
                setData={setAffectedZone}
                data={affectedZone}
                options={[
                  { id: null, name: 'Todas' },
                  ...pathologies
                    .filter((elm: any) => !elm.deleted)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                ]}
                simple
              />
            </Grid>
          )}
          <Grid item xs={6} md={3} xl={2}>
            <SelectDialog
              required
              text={labels.es.incorcamp}
              setData={setBookingType}
              data={bookingType}
              options={[
                { id: null, name: 'Todas' },
                { name: labels.es.incident, id: 'incident' },
                { name: labels.es.campaign, id: 'campaign' },
                { name: labels.es.comunPatology, id: 'comunPatology' }
              ]}
              simple
            />
          </Grid>
          {processes.length > 0 && userData.role.id !== 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                required
                text={labels.es.processes}
                setData={setProcess}
                data={process}
                options={[
                  { id: null, name: 'Todos' },
                  ...processes
                    .filter((elm) => !elm.deleted)
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                ]}
                simple
              />
            </Grid>
          )}
          {configCompanyAdmin && userData.role.id === 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                required
                text={labels.es.processes}
                setData={setProcess}
                data={process}
                options={[
                  { id: null, name: 'Todos' },
                  ...configCompanyAdmin
                    .filter((elm: any) => elm.type === 'processes' && !elm.deleted)
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                ]}
                simple
              />
            </Grid>
          )}
          {machines.length > 0 && userData.role.id !== 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                required
                text={labels.es.machines}
                setData={setMachine}
                data={machine}
                options={[
                  { id: null, name: 'Todas' },
                  ...machines
                    .filter((elm) => !elm.deleted)
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                ]}
                simple
              />
            </Grid>
          )}
          {configCompanyAdmin && userData.role.id === 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                required
                text={labels.es.machines}
                setData={setMachine}
                data={machine}
                options={[
                  { id: null, name: 'Todas' },
                  ...configCompanyAdmin
                    .filter((elm: any) => elm.type === 'machines' && !elm.deleted)
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                ]}
                simple
              />
            </Grid>
          )}
          {sectors.length > 0 && userData.role.id !== 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                required
                text={labels.es.sectors}
                setData={setSector}
                data={sector}
                options={[
                  { id: null, name: 'Todos' },
                  ...sectors
                    .filter((elm) => !elm.deleted)
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                ]}
                simple
              />
            </Grid>
          )}
          {configCompanyAdmin && userData.role.id === 'role1' && (
            <Grid item xs={6} md={3} xl={2}>
              <SelectDialog
                required
                text={labels.es.sectors}
                setData={setSector}
                data={sector}
                options={[
                  { id: null, name: 'Todos' },
                  ...configCompanyAdmin
                    .filter((elm: any) => elm.type === 'sectors' && !elm.deleted)
                    .map((elm: any) => {
                      return { name: elm.name, id: elm.id }
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                ]}
                simple
              />
            </Grid>
          )}
          <Grid item xs={6} md={3} xl={2}>
            <SelectDialog
              required
              text={labels.es.gender}
              setData={setGender}
              data={gender}
              options={[
                { id: null, name: 'Todos' },
                { name: 'Hombre', id: 'Hombre' },
                { name: 'Mujer', id: 'Mujer' },
                { name: '-', id: '-' }
              ]}
              simple
            />
          </Grid>
          <Grid item xs={6} md={3} xl={2}>
            <SelectDialog
              required
              text={labels.es.age}
              setData={setAge}
              data={age}
              options={[
                { id: null, name: 'Todos' },
                { name: '18-25', id: '1825' },
                { name: '25-30', id: '2530' },
                { name: '30-45', id: '3035' },
                { name: '35-40', id: '3540' },
                { name: '45-50', id: '4550' },
                { name: '50-55', id: '5055' },
                { name: '55-60', id: '5560' },
                { name: '60-65', id: '6065' }
              ]}
              simple
            />
          </Grid>
          <Grid item xs={6} md={3} xl={2}>
            <SelectDialog
              required
              text={labels.es.positionJob}
              setData={setJobPosition}
              data={jobPosition}
              options={[
                { id: null, name: 'Todos' },
                { id: 'role6', name: labels.es.cashier },
                { id: 'role5', name: labels.es.mainCashier },
                { id: 'role5', name: labels.es.professionalChannel },
                { id: 'role4', name: labels.es.director },
                { id: 'role5', name: labels.es.cashManager },
                { id: 'role5', name: labels.es.comercialManager },
                { id: 'role5', name: labels.es.departmentManager },
                { id: 'role5', name: labels.es.logisticManager },
                { id: 'role6', name: labels.es.seller }
              ]}
              simple
            />
          </Grid>
          {bookingType && bookingType.id === 'incident' && (
            <>
              <Grid item xs={6} md={3} xl={2}>
                <SelectDialog
                  required
                  text={labels.es.workWindow}
                  setData={setTimeWindow}
                  data={timeWindow}
                  options={[
                    { id: null, name: 'Todas' },
                    { name: 'Mañana', id: 'morning' },
                    { name: 'Tarde', id: 'afternoon' }
                  ]}
                  simple
                />
              </Grid>
              <Grid item xs={6} md={3} xl={2}>
                <SelectDialog
                  required
                  text={labels.es.daysSinceDayOff}
                  setData={setDaysOff}
                  data={daysOff}
                  options={[
                    { id: null, name: 'Todos' },
                    { name: '1', id: '1' },
                    { name: '2', id: '2' },
                    { name: '3', id: '3' },
                    { name: '4', id: '4' },
                    { name: '5', id: '5' },
                    { name: '6', id: '6' },
                    { name: '7', id: '7' },
                    { name: 'Más de 8', id: '8' },
                    { name: 'Más de 15', id: '15' }
                  ]}
                  simple
                />
              </Grid>
              <Grid item xs={6} md={3} xl={2}>
                <SelectDialog
                  required
                  text={labels.es.statusIncident}
                  setData={setIncidentStatus}
                  data={incidentStatus}
                  options={[
                    { id: null, name: 'Todas' },
                    { name: 'Alta', id: 'alta' },
                    { name: 'Sin Resolución', id: 'sinresolución' },
                    { name: 'Max Sesiones', id: 'full' }
                  ]}
                  simple
                />
              </Grid>
            </>
          )}
          <Grid item xs={6} md={3} xl={2}>
            <SelectDialog
              required
              text={labels.es.workingSince}
              setData={setTimeCompany}
              data={timeCompany}
              options={[
                { id: null, name: 'Todos' },
                { name: '3 meses', id: '90' },
                { name: '6 meses', id: '180' },
                { name: '9 meses', id: '270' },
                { name: '1 año', id: '365' },
                { name: '2 años', id: '730' },
                { name: '3 años', id: '1095' },
                { name: '5 años', id: '1825' },
                { name: '8 años', id: '2920' },
                { name: '10 años', id: '3650' },
                { name: '10 o + años', id: '3651' }
              ]}
              simple
            />
          </Grid>
        </Grid>
        <IconButton sx={{ mx: 2 }} color="primary" onClick={cleanFilters}>
          <FilterAltOffIcon />
        </IconButton>
      </Box>
      <Chart title={'Sesiones'} dataChart={chartData && chartData} />
    </div>
  )
}
