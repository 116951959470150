import app from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
import firebaseConfig from './config'
import {
  getAuth,
  linkWithRedirect,
  signInWithRedirect,
  OAuthProvider,
  getRedirectResult,
  signInWithPopup,
  signInWithCredential,
  getAdditionalUserInfo,
  linkWithCredential,
  UserCredential,
  AdditionalUserInfo
} from 'firebase/auth'
import Swal from 'sweetalert2'
import { sign } from 'crypto'
import { logoutSwalConfig } from 'src/utils/swal.utils'
import { sectors, stores, roles } from '../scripts/data'
import { storesObj, sectorsObj, rolesObj } from '../scripts/datats'

function removeAccents(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export class Firebase {
  app: app.app.App
  auth: app.auth.Auth
  db: app.firestore.Firestore
  files: app.storage.Storage
  persi: any
  functions: app.functions.Functions

  constructor() {
    app.initializeApp(firebaseConfig)
    this.app = app.app()
    this.auth = app.auth()
    this.db = app.firestore()
    this.files = app.storage()
    this.persi = getAuth()
    this.functions = app.functions()
  }

  async loginOIDC() {
    const provider = new OAuthProvider('oidc.adeo')
    provider.addScope('profile')
    provider.addScope('thirdprofile')
    provider.addScope('office')
    provider.addScope('advprofile')
    provider.addScope('email')
    provider.addScope('lastmile')
    provider.addScope('openid')
    provider.addScope('groups')
    provider.addScope('reflex')
    provider.addScope('picture')
    provider.addScope('piivo')
    provider.addScope('offline_access')
    provider.setCustomParameters({
      claims:
        '{businesscategory,preferredlanguage,"businesscategory","cn","co","departmentnumber","displayname","email","employeeNumber","employeeType","facsimileTelephoneNumber","family_name","given_name","jpegPhoto","l","mail"mailalternateaddress","manager","memberof","mobile","name","o","ou","pi.sri","preferredlanguage","privAccountStatus","privbusinessareacode","privbusinesscategorycode","privContractEndDate","privContractStartDate","privdefaultdepartment","privDepartmentCode","privmaindepartmentcode","privmaindepartmentnumber","privmainprivdepartmentcode","privO365GUID","privO365Licence","privO365UPN","privoperationalbusinesscategorycode","privOperationalDepartmentCode","sid","sub","telephoneNumber","title","uid"}'
    })

    const auth = await getAuth()
    return signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log('result', result)

        const userData: AdditionalUserInfo | null = await getAdditionalUserInfo(result)
        console.log('here', userData)

        // Expresión regular para buscar específicamente 'ROLE-METAPHYSIO-ROLE_' seguido de un número
        const pattern = /ROLE-METAPHYSIO-ROLE_(\d+)/

        // Filtrar y extraer el número
        const roleNumber = (userData?.profile?.memberof as any).reduce((acc: any, item: any) => {
          const match = item.match(pattern)
          if (match) {
            acc.push(match[1]) // extrae el número encontrado después de 'ROLE-METAPHYSIO-ROLE_'
          }
          return acc
        }, [])

        if (!roleNumber) {
          const user = auth.currentUser
          user
            ?.delete()
            .then(async () => {
              Swal.fire({
                icon: 'error',
                width: 440,
                confirmButtonText: 'OK',
                confirmButtonColor: '#1870c7',
                html: `<h6>Acceso no permitido</h4>`,
                showConfirmButton: true
              })
              console.log('user deleted due to not permission')
            })
            .catch((error) => {
              console.log(error)
            })

          return
        }

        if (userData?.isNewUser) {
          console.log('user', result.user.uid)
          const userInfo = await app.firestore().collection('users').doc(result.user.uid)
          if (userData.profile) {
            await app
              .firestore()
              .collection('pingidlogs')
              .add({ ...userData?.profile, success: 'new' })
          }
          await userInfo
            .set({
              birthday: '01/01/1900',
              company: { id: 'xnR745oszYN4mRTAJiLY', name: 'Obramat (BRICOLAJE BRICOMAN, S.L.U.)' },
              companyId: 'xnR745oszYN4mRTAJiLY',
              createdAt: Date.now(),
              comunPatology: { id: false, name: 'No' },
              comunPatologyBookings: 0,
              deleted: false,
              createdBy: 'oidc',
              method: 'oidc',
              email: result.user.email,
              employeeId: userData?.profile?.uid,
              fullName: userData?.profile?.name,
              id: result.user.uid,
              lastname: userData?.profile?.family_name,
              lastname2: '',
              name: userData?.profile?.given_name,
              role: { ...rolesObj[String(userData?.profile?.title)], id: `role${roleNumber}` },
              sector: userData?.profile?.departmentnumber ? sectorsObj[String(userData?.profile.departmentnumber)] : null,
              // role: {
              //   ...(roles.find((role) => removeAccents(role.name).toLowerCase() === String(userData?.profile?.title)?.toLowerCase()) || {
              //     id: `role${roleNumber}`,
              //     name: userData?.profile?.title
              //   }),
              //   id: `role${roleNumber}`
              // },
              // sector: userData?.profile?.departmentnumber
              //   ? [
              //       sectors.find(
              //         (sector) => removeAccents(sector.name).toLowerCase() === String(userData?.profile?.departmentnumber).toLowerCase()
              //       )
              //     ]
              //   : null,
              store: storesObj[String(userData?.profile?.businesscategory)],
              storeId: storesObj[String(userData?.profile?.businesscategory)].id,
              // store: stores.find((store) =>
              //   removeAccents(store.name).toLowerCase().includes(String(userData?.profile?.businesscategory).toLowerCase())
              // ),
              // storeId: stores.find((store) =>
              //   removeAccents(store.name).toLowerCase().includes(String(userData?.profile?.businesscategory).toLowerCase())
              // )?.id,
              workingSince: String(userData?.profile?.privContractStartDate).split('/').reverse().join('/'),
              gender: { id: '-', name: '-' }
            })
            .then(async () => console.log('done info'))
          if (userData?.profile?.departmentnumber === 'ACABADO') {
            console.log('llama')
            const updateEmployeeSector = app.functions().httpsCallable('getSectorSexBirthday')

            try {
              return await updateEmployeeSector(result.user.email)
            } catch (error) {
              console.log(error)

              throw new Error('Hubo un error mientras actualizabamos el sector')
            }
          }
        } else {
          console.log('usuario ya existia')
          const userInfo = await app.firestore().collection('users').doc(result.user.uid)
          if (userData?.profile) {
            await app
              .firestore()
              .collection('pingidlogs')
              .add({ ...userData?.profile, success: 'old' })
          }

          await userInfo
            .update({
              deleted: false,
              email: result.user.email,
              employeeId: userData?.profile?.uid,
              fullName: userData?.profile?.name,
              lastname: userData?.profile?.family_name,
              lastname2: '',
              name: userData?.profile?.given_name,
              // role: {
              //   ...(roles.find((role) => removeAccents(role.name).toLowerCase() === String(userData?.profile?.title)?.toLowerCase()) || {
              //     id: `role${roleNumber}`,
              //     name: userData?.profile?.title
              //   }),
              //   id: `role${roleNumber}`
              // },
              // sector: userData?.profile?.departmentnumber
              //   ? [
              //       sectors.find(
              //         (sector) => removeAccents(sector.name).toLowerCase() === String(userData?.profile?.departmentnumber).toLowerCase()
              //       )
              //     ]
              //   : null,
              role: { ...rolesObj[String(userData?.profile?.title)], id: `role${roleNumber}` },
              sector: userData?.profile?.departmentnumber ? sectorsObj[String(userData?.profile.departmentnumber)] : null,
              store: storesObj[String(userData?.profile?.businesscategory)],
              storeId: storesObj[String(userData?.profile?.businesscategory)].id
            })
            .then(() => console.log('done info'))
          if (userData?.profile?.departmentnumber === 'ACABADO') {
            console.log('llama')
            const updateEmployeeSector = app.functions().httpsCallable('getSectorSexBirthday')

            try {
              return await updateEmployeeSector(result.user.email)
            } catch (error) {
              console.log(error)

              throw new Error('Hubo un error mientras actualizabamos el sector')
            }
          }
        }

        // }
      })
      .catch(function (error) {
        console.log(error)
        if (error.toString().includes('account-exists-with-different-credential')) {
          console.log('here4')

          const credential = OAuthProvider.credentialFromError(error)
          if (credential) {
            Swal.fire({
              title: 'Vincular cuenta',
              confirmButtonText: 'Vincular',
              confirmButtonColor: '#1870c7',
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading(),
              html: `
    <span class="swal2-span" styles={{fontSize:'10px'}}>Introduce tu email y contraseña de Metaphysio</span>
    <input id="swal-input1" class="swal2-input" placeholder="Email" type="email">
    <input id="swal-input2" class="swal2-input" placeholder="Contraseña" type="password">
  `,
              focusConfirm: false,
              preConfirm: () => {
                app
                  .auth()
                  .signInWithEmailAndPassword(
                    (document.getElementById('swal-input1') as HTMLInputElement)!.value,
                    (document.getElementById('swal-input2') as HTMLInputElement)!.value
                  )
                  .then((result) => {
                    console.log(result)
                    if (credential && result.user) {
                      console.log('here7')
                      return linkWithCredential(result.user, credential)
                        .then((linkResult: any) => {
                          // Sign in with the newly linked credential
                          return app.auth().signInWithCredential(credential)
                        })
                        .then((signInResult: any) => {
                          // Save the merged data to the new user
                          console.log(signInResult, 'merged')
                          window.location.reload()
                        })
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: 'error',
                      width: 440,
                      confirmButtonText: 'OK',
                      confirmButtonColor: '#1870c7',
                      html: `<h6>Ha habido un error, inténtelo de nuevo</h4>`,
                      showConfirmButton: true
                    })
                    // If there are errors we want to undo the data merge/deletion
                    console.log('Sign In Error', error)
                  })
              }
            })
          }
        }
      })
  }

  async login(email: string, password: string, persistence: boolean): Promise<void> {
    if (!persistence) {
      await app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
      await this.auth.signInWithEmailAndPassword(email, password)
      // .then(function (userCredential) {
      // userCredential?.user?.updateEmail('sara.balado.f@obramat.es')
      // })
    } else {
      await app.auth().setPersistence(app.auth.Auth.Persistence.LOCAL)
      await this.auth.signInWithEmailAndPassword(email, password)
      //   .then(function(userCredential) {
      //     userCredential?.user?.updateEmail("carlos-alberto.rojasb@obramat.es")
      // })
    }
  }

  async logout(): Promise<void> {
    return await this.auth.signOut()
  }
}

const firebase = new Firebase()
export default firebase
