import firebase from 'src/firebase/firebase'

export const getCompaniesData = async (id: string): Promise<any> => {
  return firebase.db
    .collection('companies')
    .doc(id)
    .get()
    .then((response) => {
      const data = response.data()
      return data
    })
    .catch(() => {
      throw new Error('Hubo un error mientras se intentaba recuperar la empresa')
    })
}

export const getAllCompanies = async (): Promise<any> => {
  try {
    return await firebase.db
      .collection('companies')
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaban recuperar la lista de empresas')
  }
}

export const getAllCompanyConfig = async (companyId: string): Promise<any> => {
  try {
    return await firebase.db
      .collection('companyConfiguration')
      .where('companyId', '==', companyId)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          return []
        }
        return snapshot.docs.map((doc: any) => {
          return { id: doc.id, ...doc.data() }
        })
      })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba recuperar la configuración de la compañia')
  }
}

export const createCompanyConfig = async (companyNewConfig: Record<string, any>): Promise<any> => {
  try {
    return await firebase.db.collection('companyConfiguration').add(companyNewConfig)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear la configuracion de la empresa')
  }
}

export const updateCompanyConfig = async (companyConfig: Record<string, any>): Promise<any> => {
  try {
    return firebase.db.collection('companyConfiguration').doc(companyConfig.id).update(companyConfig)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de la configuración de la compañia')
  }
}

export const deleteCompanyConfig = async (uid: string): Promise<any> => {
  try {
    return firebase.db.collection('companyConfiguration').doc(uid).update({ deleted: true })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la configuración de la compañia')
  }
}

export const createCompany = async (companyData: Record<string, any>): Promise<any> => {
  try {
    return await firebase.db.collection('companies').add(companyData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba crear la empresa')
  }
}

export const deleteCompany = async (uid: string): Promise<any> => {
  try {
    return firebase.db.collection('companies').doc(uid).update({ deleted: true })
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba eliminar la empresa')
  }
}

export const updateCompany = async (companyData: Record<string, any>, id: string): Promise<any> => {
  try {
    return firebase.db.collection('companies').doc(id).update(companyData)
  } catch (error) {
    throw new Error('Hubo un error mientras se intentaba actualizar los datos de la empresa')
  }
}

export const checkEmployeesIds = async (idSelected: string): Promise<any> => {
  try {
    const querySnapshot = await firebase.db.collection('users').where('employeeId', '==', idSelected).get()

    return querySnapshot.size
  } catch (error) {
    console.log('error while getting users employee ids', error)
  }
}
